<template>
  <div>
    <div class="BottomDrawer__overlay" @click="handleClose"></div>
    <div class="BottomDrawer__wrapper">
      <div class="BottomDrawer__header">
        <slot name="header" />
        <button class="BottomDrawer__close-button" @click="handleClose">
          <IconClose />
        </button>
      </div>
      <div class="BottomDrawer__content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const emit = defineEmits<{ (e: "close"): void }>();

const handleClose = () => {
  emit("close");
};
</script>
<style scoped lang="scss">
.BottomDrawer {
  &__wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background: $color-basic-white;
    border-radius: 20px 20px 0 0;
    z-index: 2;
    max-height: calc(100vh - 122px);
    @include md {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      min-width: 380px;
      max-width: 450px;
      border-radius: 20px;
      height: fit-content;
      max-height: 100dvh;
      overflow: hidden;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-basic-overlay;
    z-index: 2;
  }
  &__header {
    @include header-xs-bold;
    color: $color-element-dark-strong;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  &__close-button {
    border: none;
    background: none;
    cursor: pointer;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: calc(100vh - 212px);
  }
}
</style>
